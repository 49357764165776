.footerDiv {
    display: flex;
    background-color: #d0e5f8;
    text-align: center;
    justify-content: space-between;
    align-items: center;

}

.bigFooterDiv {

    height: var(--footer-height);
    padding-left: 2rem;
    padding-right: 2rem;
}

.smallFooterDiv {

    height: var(--footer-small-height);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.logo{
    width: 200px;
    height: 60px;
}

.name{
    font-size: 1.5rem;
}