.header{
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    font-size: 1.3rem;
    background-color: rgb(227, 221, 221);
    border-bottom: 1px rgb(134, 132, 132) solid;
}

.modalTitle {
    font-weight: 700;
}

.closeBtn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(244, 239, 239);
    border: solid 1px rgb(213, 205, 205); 
}

.closeBtn :hover{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(205, 202, 202);
    border: solid 1px rgb(205, 202, 202); 
}

.mainContent {
    padding: 1rem 0.2rem 1.2rem 0.2rem;
}

.displayRecordHeader {
    margin: 1rem 0.5rem 1rem 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.goBackToListBtnBox {
    align-self: flex-end;
    flex-shrink: 3;
}

.inputCard {
    padding: 0.5rem;
    border-radius: 10px;
    background-color: #f8f9fa;
    border: 1px solid #adb5bd;
}
