.header{

    display: flex;
    justify-content: space-between;
    background-color: var(--bs-primary);
    color: aliceblue;
    padding: 0.5rem;
    height: 7vh;
}

.modalTitle{

    font-size: 1.5rem;
    font-weight: 400;
}

.closingBtn{

    cursor: pointer;
    border-radius: 50%;
    width: 2.60rem;
    height: 2.60rem;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: aliceblue;
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.87);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.87);
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.87);
}

.closingBtn:hover {
    background-color: rgb(217, 227, 230);
}

.closingBtn:active {
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
}

.modalBody{

    min-height: 7rem;
    font-size: 1.2rem;
    overflow: auto;
    width: 100%;
}


.hr{
    padding: 0;
    margin: 0;
}

.footer{
    height: 7vh;
    background-color: rgb(231, 235, 249);
    padding: 0.5rem;
}