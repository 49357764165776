
:root {
  --footer-height: 13vh;
  --footer-small-height: 4rem;
  --header-height: 4.5rem;
  --header-bc-blue: #145DA0;
  --color-neon-pink:#FFDCF7;
  --color-neon-blue:#7CDFFF;
  --color-neon-green:#32FF6A;
  --color-neon-purple:#B7ABFB;
  --bs-gutter-x: 1.5rem;
}

* {
  margin: 0;
  padding: 0;
  text-align: right;
}

.App {

}

.MyInvalid-feedback{
  color: red;
}

.modalHeader{
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  font-size: 1.3rem;
  background-color: rgb(227, 221, 221);
  border-bottom: 1px rgb(134, 132, 132) solid;
}

.modalTitle {
  font-weight: 700;
}

.modalCloseBtn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(244, 239, 239);
  border: solid 1px rgb(213, 205, 205); 
}

.modalCloseBtn :hover{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(205, 202, 202);
  border: solid 1px rgb(205, 202, 202); 
}

.modalMainContent {
  padding: 1rem 0.2rem 1.2rem 0.2rem;
}