
.loginPageLayout {
    padding-top: 3rem;
    min-height: calc(100vh - var(--footer-height));
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
}

.pageTitle{
    font-size: 2.2rem;
    padding-bottom: 2.5rem;
}

.formTitle {
    font-size: 1.6rem;
    padding-bottom: 0.5rem;
}

.formContainer {

    width: 40%;
    padding: 2rem;
    border-radius: 8px;

    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
}

.form {
    
    display: flex;
    flex-direction: column;
    justify-self: center;
}

@media (max-width: 992px) {
    .formContainer {
        width: 80%;
    }
}
