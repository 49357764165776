


.headerTitle {
    font-size: 1.4rem;
    text-decoration-thickness: 2px;
    text-decoration-line: underline;
    text-decoration-color: rgb(56, 128, 244);
}

.formSection {
    padding: 0.5rem;
    background-color: var(--bs-gray-100);
    border-radius: 10px;
    border: solid var(--bs-gray-300) 1px;
    transition: all 0.2s;
    min-height: 100%;
}

.formSection:hover {
    border-color: var(--bs-primary-border-subtle);
    /*box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);*/
}
