
.emailSendTo {
    text-decoration: underline;
    text-decoration-thickness: 4px;
    text-decoration-color: var(--bs-blue);
}

.cityEmail {
    background-color: var(--bs-primary-bg-subtle);
    padding: 0.2rem;
    border-radius: 4px;
    border: none;
    outline: none;
    width: fit-content;
    min-width: 15rem;
}

.cityEmail:focus {
    border: solid 1px black;
    background-color: inherit;
    border: 2px solid gray;
    outline-color: darkgrey 3px;
}

.rejectionOption {
    font-size: 1.2rem;
    text-decoration: underline;
}

.formTitle {
    font-size: 1.2rem;
    text-decoration: underline;
}

.emailContainer {

    background-color: var(--bs-gray-100);
    border: 1px solid var(--bs-gray-200);
    padding: 0.2rem;
    margin-bottom: 1rem;
    border-radius: 4px;
}

.smallTitle {

    text-decoration: underline;
    text-decoration-color: var(--bs-primary);
}