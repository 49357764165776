
.formBox {
  padding: 0.5rem;
  border-radius: 10px;
  transition: all 1s;
  outline: none #ffffff 1.5px;
  /*background-color: transparent;*/
  background-color: var(--bs-light);
}

.formBox:hover {
  box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
  /*outline: solid #6c757d 1px;*/
}

.clearInputButton {
  /* button position */
  position: absolute;
  right: 4px;
  top: 4px;
  bottom: 0;
  /* button appearane */
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  appearance: none;
  border: none;
  border-radius: 50%;
  background: gray;
  margin: 0;
  padding: 2px;
  color: white;
  font-size: 13px;
  cursor: pointer;
  /* hide the button initially */
  display: none;
}

.inputContainer {
  position: relative;
  display: inline-block;
}

.checkBoxForm {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.formCheckLabel {
line-break: auto;
}

.formCheckInput {
  width: 1.2rem;
  height: 1.2rem;
  border: black 2px solid;
  margin-left: 0.5rem;
}

.inputErrorMessage {
  color: red;
  font-size: 0.85em;
}

.radioBoxInput {
  width: 1.25em;
  height: 1.25rem;
  transition: all 0.125s ease-in-out;
}

.radioBoxInput:hover {
  scale: 1.125;
  cursor: pointer;
}

.radioBoxInput:disabled {
  scale: 1;
}


.radioBoxInputPrimary { accent-color: var(--bs-primary); }

.radioBoxInputSecondary { accent-color: var(--bs-secondary); }

.radioBoxInputSuccess { accent-color: var(--bs-success); }

.radioBoxInputDanger { accent-color: var(--bs-danger); }

.radioBoxInputWarning { accent-color: var(--bs-warning); }

.radioBoxInputInfo { accent-color: var(--bs-info); }

.radioBoxInputLight { accent-color: var(--bs-light); }

.radioBoxInputDark { accent-color: var(--bs-dark); }

.radioBoxInputSuccess:disabled { accent-color: rgba(var(--bs-success-rgb), 0.5); }

.radioBoxInputPrimary:hover { accent-color: rgba(var(--bs-primary-rgb), 0.5); }

.radioBoxInputSecondary:hover { accent-color: rgba(var(--bs-secondary-rgb), 0.5); }

.radioBoxInputSuccess:hover { accent-color: rgba(var(--bs-success-rgb), 0.5); }

.radioBoxInputDanger:hover { accent-color: rgba(var(--bs-danger-rgb)); }

.radioBoxInputWarning:hover { accent-color: rgba(var(--bs-warning-rgb)); }

.radioBoxInputInfo:hover { accent-color: rgba(var(--bs-info-rgb)); }

.radioBoxInputLight:hover { accent-color: rgba(var(--bs-light-rgb)); }

.radioBoxInputDark:hover { accent-color: rgba(var(--bs-dark-rgb)); }

.radioBoxLabel {
  font-size: 1.2em;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  width: fit-content;
  cursor: pointer;
}

.radioBoxLabel:disabled {
  transform: none;
}

.eyeIconSpan {
  position: absolute; 
  left: 0.65rem;
  top: 0.65rem;
  border-radius: 50%;
  padding: 0.25rem;
}

.eyeIconSpan:hover {
  box-shadow: 0px 0px 48px 5px rgba(0,0,0,0.1);
}

.eyeIcon {
  color: '#98D8EF'
}

.eyeIcon:hover {
  cursor: pointer;
  color: '#EAE2C6',
}