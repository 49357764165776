
.page{

    row-gap: 2rem;
    /*min-height: calc(100vh - var(--header-height) - var(--footer-small-height));*/
    padding-top: 2rem;
    padding-bottom: 2rem;

    /*--bs-gutter-x: 1.5rem;*/
    
    width: 100%;
    padding-right: calc(var(--bs-gutter-x)* 0.5);
    padding-left: calc(var(--bs-gutter-x)* 0.5);
    margin-right: auto;
    margin-left: auto;
}
/*
    --bs-breakpoint-xs: 0;
    --bs-breakpoint-sm: 576px;
    --bs-breakpoint-md: 768px;
    --bs-breakpoint-lg: 992px;
    --bs-breakpoint-xl: 1200px;
    --bs-breakpoint-xxl: 1400px;
*/
@media (min-width: 576px) {

    .page{

        max-width: 95%;
    }
}
/*
@media (min-width: 768px) {
    
    .page{
        max-width: 540px;
    }
}
*/
@media (min-width: 992px) {
    .page{
        max-width: 90%;
    }
}
/*
@media (min-width: 1200px) {
    .page{
        max-width: 85%;
    }
}
*/
@media (min-width: 1400px) {
    .page{
        max-width: 85%;
    }
}