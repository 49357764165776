.formFloating {
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    align-items: center;
    font-size: 1.3rem;
}

.select {
    border: none;
    font-weight: 500;
    border-radius: 5px;

    background-color: rgb(207, 203, 203);
}

.select:hover {
    background-color: inherit;
    border: 1px black solid;
    
}

.select:focus {
    background-color: white;
    border: 1px black solid;
}

.select:active {
    background-color: white;
    border: none;
}

.label { 
    
}