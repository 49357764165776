.alert {

    display: flex;
    justify-content: space-between;
}

.btnCloseAlet {

    font-size: 1.8rem;
    color: inherit;
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
   
    transition: all 0.1s ease 0s;
}

.btnCloseAlet:hover {

    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.btnCloseAletIcon:hover {

    opacity: 0.9;
}

