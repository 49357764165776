.pagingContainer{
    
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.btnPaddingLeft{

    margin-left: 0.2rem;
}

.currentPage{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.4rem;
    width: 2.2rem;
    height: 2.2rem;
    font-weight: 700;
    border-radius: 0.25rem;
    color: white;
    background-color: var(--bs-primary);
}

.span{
    width: 2rem;
    height: 2rem;
    padding: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
}

.span:hover{
    background-color: var(--bs-gray-300);
    color: rgb(7, 83, 169);
    border-radius: 5px;
}

.pagingBtn{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: white;
    border-radius: 0.25rem;
    border: var(--bs-primary) 1px solid;
    padding: 0rem;
    color: white;
    background-color: var(--bs-primary);
    width: 2.7rem;
    height: 2rem;
    font-size: 2rem;
    cursor: pointer;
}

.pagingBtn:hover{
    background-color: rgb(17, 92, 171);
}

.numOrange {
    font-weight: bold;
    color: rgb(236, 85, 30);
}

.totalCount {
    align-self: flex-end;
}
