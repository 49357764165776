.myBtn {
    /*transition: all 0.4s;*/
}

.btnNowrap{
    white-space: nowrap;
}

.icon{
    font-size: 1.0rem;
}

.startIcon{
    margin-right: 0.4rem;
}

.endIcon{
    margin-left: 0.4rem;
}

.backToTopButton {
position: fixed;
bottom: 45px;
right: 45px;
height: 4.5rem;
width: 4.5rem;
border-radius: 50%;
color: rgb(49, 48, 48);
border-style: none;
background-color: rgb(208, 139, 12);
font-size: 1.2rem;
transition: all 0.3s;
text-align: center;
}

.myBtn:hover {

    /*transform: scale(1.05);*/
}

.backToTopButton:hover {

    transform: scale(1.3);
}