
.ListOfRows {

}

.rowCardDiv {
    display: grid; 
    grid-auto-rows: 1fr; 
    grid-template-columns: 3fr 97fr; 
    grid-template-rows: 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "index body";
    border-radius: 10px;
    padding: 0.5rem;
    border: solid #6c757d 1px;
    background-color: var(--bs-light);
    margin-bottom: 1rem;
}

.index {

    grid-area: index;
    font-size: 1.5rem;
}

.body {

    grid-area: body;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 1rem;
   
}

.rowsDiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 2rem;
}

.footer {
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
}

.rowCardItem {

}

.rowCardItemLabel {
    font-weight: bold;
}

.rowCardItemValue {
    
}

.spanPaymentMethodCash {
    background-color: var(--bs-success-bg-subtle);
}

.spanPaymentMethodCard {
    background-color: var(--bs-primary-bg-subtle);
}