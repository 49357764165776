.filesContainer {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.filesContainer {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1rem;
    justify-content: center;
}

.listContainer {
    direction: rtl;
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    justify-content: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: var(--bs-gray-100);
    width: 100%;
}

.bigContainer {
    width: 100%;
    overflow-y: auto;
    height: 35rem;
    direction: ltr;
    background-color: var(--bs-gray-100);
}

.pdf {
    width: 100%;
    height: 35.5rem;
}

.expandIcon {
    font-size: 1.5rem;
    transition: all 0.3s;
    color: rgb(32, 91, 17);
}

.expandIcon:hover {
    color: var(--bs-primary-text-emphasis);
}

.cardBody {

    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.itemListImage {
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.itemList {

    border-radius: 10px;
    border: solid var(--bs-gray-300) 1px;
    width: 12rem;
    height: 12rem;
    overflow: hidden;
}

.itemListFileContainer {
    width: 12rem;
    height: 12rem;
    max-height: 12rem;
}

.itemListTitle {
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}