
.filesModalContent {
    display: grid;
    grid-template-columns: 3fr 2fr;
    height: 100%;
}

.filesModalDetailsItem {
    height: 100%;
    padding: 1rem;
}

.filesModalFilesItem {
    max-height: 100%;
    overflow: auto;
}

.filesContainer{

    overflow: auto;
}

.fileViewerLayout {
    width: 100%;
}

.image {
    width: 100%;
}

.displaySmallInput {
    font-size: 1rem;
}

.fileViewerLayoutCro {
    position: relative;
}

.rotateContainersBtns {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    row-gap: 0.5rem;
}

.roundBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    background-color: rgba(255, 254, 253, 0.343);
    border: solid 1px rgba(255, 254, 253, 0.343);
    font-size: 1.2rem;
    text-align: center;
    color: rgb(0, 0, 0);
}

.roundBtn:hover {
    background-color: rgb(227, 218, 218);
    border-color: rgb(227, 218, 218);
}

.noneStyleBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    width: 2.5rem;
    padding: 0.2rem;
    border: none;
    background-color: inherit;
}

.noneStyleBtn:hover {

    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
    border: none;
    background-color: rgb(194, 186, 186);
    text-align: center;
    color: #ffffff;
}

.previewFileBtnsContainet {
    display: flex;
    column-gap: 1rem;
    justify-content: center;
    align-items: center;
    height: 4rem;
    padding: 0.5rem;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.previewFileBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 10px;
    border: none;
    font-size: 1.5rem;
    background-color: rgba(232, 229, 229, 0.566);
}

.previewFileBtn:hover {
    background-color: #444445;
    color: #ffffff;
}

.previewFileBtnActive {
    height: 3rem;
    width: 3rem;
    font-size: 2.2rem;
    outline-width: 2px;
    outline-style: solid;
    outline-color: #9c9c9d;
    background-color: #444445;
    color: #ffffff;
}

@media only screen and (max-width: 992px) {
    .btnContainers {
        flex-direction: column-reverse;
        column-gap: 0;
        row-gap: 0.5rem;
    }

    .filesModalContent {
        display: block;
    }

    .filesModalDetailsItem {
        display: none;
    }
    
  }