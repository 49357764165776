.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.200);
}

.modal {
  position: fixed;
  top: 30vh;
  left: 5%;
  width: 90%;
  height: 15rem;
  z-index: 30;
  animation: slide-down 300ms ease-out forwards;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
  justify-content: center;
}

.text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

@media (min-width: 768px) {
  .modal {
    width: 15rem;
    left: calc(50% - 7.5rem);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}